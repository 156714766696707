import Icon, { IconProps } from "./icon";

// Indicates a numerical value. Useful for notifications.

export interface BadgeProps {
  color: "white" | "neutral";
  value: number;
  label?: string;
  showZero: boolean;
  icon?: IconProps;
  children?: React.ReactNode;
  anchorOrigin?: BadgeAnchorProps;
}
interface BadgeAnchorProps {
  vertical: "top" | "bottom";
  horizontal: "left" | "right";
}

function Badge(props: BadgeProps) {
  const anchorClasses: string | undefined =
    props.anchorOrigin || props.children
      ? `absolute ${
          props.anchorOrigin?.horizontal === "left"
            ? "left-0 -translate-x-1/2"
            : "right-0 translate-x-1/2"
        } ${
          props.anchorOrigin?.vertical === "bottom"
            ? "bottom-0 translate-y-1/2"
            : "top-0 -translate-y-1/2"
        }`
      : undefined;
  return (
    <>
      {props.children ? (
        <div
          aria-label={`${props.value} ${props.label}`}
          className={`relative m-4 inline-flex`}
        >
          {props.children}
          <div className={anchorClasses}>
            <Indicator {...props} />
          </div>
        </div>
      ) : (
        <Indicator {...props} />
      )}
    </>
  );
}

function Indicator(props: BadgeProps) {
  const classes: string = `${
    props.color === "white" ? "bg-surface" : "bg-surface-600"
  } inline-flex whitespace-nowrap px-2 py-1 rounded-full justify-center items-center text-title-sm-bold text-secondary-100 uppercase gap-[0.13rem] h-6`;
  return (
    <div className={classes}>
      {props.icon && (
        <Icon
          idKey={props.icon.idKey}
          name={props.icon.name}
          gradient={props.icon.gradient}
          size={props.icon.size || "sm"}
        />
      )}
      {(props.value > 0 || props.showZero) && (
        <span>
          {props.value} {props.label}
        </span>
      )}
    </div>
  );
}


export default Badge;
