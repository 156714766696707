"use client";
import React, { useState, useEffect } from "react";

function formatRelativeTime(date: Date, short?: boolean) {
  const delta = Date.now() - date.getTime();
  const absoluteDelta = Math.abs(delta);
  const seconds = absoluteDelta / 1000;
  if (seconds < 60) {
    return short
      ? `${Math.round(seconds)}s ago`
      : `${Math.round(seconds)} seconds ago`;
  }
  const minutes = seconds / 60;
  if (minutes < 60) {
    return short
      ? `${Math.round(minutes)}m ago`
      : `${Math.round(minutes)} minutes ago`;
  }
  const hours = minutes / 60;
  if (hours < 24) {
    return short
      ? `${Math.round(hours)}h ago`
      : `${Math.round(hours)} hours ago`;
  }
  const days = hours / 24;
  if (days < 7) {
    return short ? `${Math.round(days)}d ago` : `${Math.round(days)} days ago`;
  }
  const weeks = days / 7;
  if (weeks < 4.3) {
    return short
      ? `${Math.round(weeks)}w ago`
      : `${Math.round(weeks)} weeks ago`;
  }
  const months = days / 30.44;
  if (months < 12) {
    return short
      ? `${Math.round(months)}mo ago`
      : `${Math.round(months)} months ago`;
  }

  const years = days / 365;
  return short ? `${Math.round(years)}y ago` : `${Math.round(years)} years ago`;
}

function RelativeTime({ date, short }: { date: string; short?: boolean }) {
  const [relativeTime, setRelativeTime] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const localDate = new Date(date);
      setRelativeTime(formatRelativeTime(localDate, short));
    };

    updateTime();
    const intervalId = setInterval(updateTime, 60000);

    return () => clearInterval(intervalId);
  }, [date]);

  return <span>{relativeTime}</span>;
}

export default RelativeTime;
